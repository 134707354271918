import axiosApi from '@/api/axiosApi'

export const listAll = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/video/listAll',
    data
})

export const pageWith = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/video/pageWith',
    data
})

export const getVideoTypeAll = () => axiosApi({
    method: 'GET',
    url: '/intserv/video/getVideoTypeAll'
})

export const getVideoTypeChilds = (data) => axiosApi({
    method: 'GET',
    url: `/intserv/video/getVideoTypeChilds/${data}`
})