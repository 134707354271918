<template>
    <div class="video-library">
        <div class="operation-bar">
            <div class="container">
                <div class="search-bar">
                    <div class="title">{{$i18n.t('videoLibrary.title')}}</div>
                    <div class="tabs-header-search">
                        <!-- $i18n.t('support.searchPlaceholder') -->
                        <el-input :placeholder="$i18n.t('videoLibrary.searchVideo')" v-model="keyword" @blur="search" @keyup.native.enter="search">
                            <i class="el-icon-search el-input__icon support-search__icon" @click="search" style="font-size: 20px;cursor: pointer" slot="suffix"/>
                        </el-input>
                    </div>
                </div>
                <el-checkbox-group v-model="languageFilters" @change="radioChangeEvent">
                    <div class="doc-filter-list">
                        <span class="doc-filter-label">{{ $i18n.t('documentation.languageFilter') }}</span>
                        <div class="doc-filter-value" style="padding-right: 50px;">
                            <div class="doc-filter-item" style="margin-bottom: 15px;" v-for="(item,index) in languages" :key="index">
                                <el-checkbox :label="item.code">{{ item.name }}</el-checkbox>
                            </div>
                        </div>
                    </div>
                </el-checkbox-group>
                <div class="radios-bar">
                    <el-radio-group v-model="radio" @change="radioChangeEvent">
                        <el-radio label="All">{{$i18n.t('videoLibrary.all')}}</el-radio>
                        <el-radio v-for="(item, index) in videoType" :key="index" :label="item.id">{{$i18n.t(`videoLibrary.${item.type.replace(item.type[0], item.type[0].toLowerCase()).replaceAll(' ', '')}`)}}</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div class="vedio-list">
            <div class="container">
                <div class="t-selector" v-if="videoTypeChilds.length">
                    <div class="t-options">
                        <div class="t-option-shell" v-for="(item, index) in videoTypeChilds" :key="index" @click="selectedEvent(item)">
                            <div :class="['t-option', tSelecteVal.includes(item.id) ? 'is-selectd': '']">
                                <span>{{item.type}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="display: flex; flex-wrap: wrap;">
                <div class="vedio" v-for="(item, index) in videoList" :key="index">
                    <div class="vedio-content">
                        <!-- <div class="vedio-cover" @click="jump2Other(item.src)">
                            <img src="@/assets/image/activity/minisite/play.png" class="partner-voices-item-btn"/>
                            <img style="width: 100%; height: 100%;" :src="item.coverSrc"/>
                        </div> -->
                        <div class="vedio-cover">
                            <iframe width="100%" height="100%" :src="item.netSrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="vedio-title">{{item.title}}</div>
                        <div class="pubdate"><div>{{item.createTime | dataSplit}}</div> <div class="el-icon-download download" @click="download(item)"> Download</div></div>
                    </div>
                </div>
            </div>
        </div>
        <VideoPopup ref="videoPopup"/>
    </div>
</template>

<script>
import {listAll, getVideoTypeAll, getVideoTypeChilds} from '@/api/video'
import {videoPlayer} from 'vue-video-player';
import 'video.js/dist/video-js.css';
import VideoPopup from '../activity/minisite/videoPopup';
import {initDicts} from '@/utils/commons';
export default {
    name: 'videoLibrary',
    components: {
      videoPlayer,
      VideoPopup
    },
    data() {
        return {
            keyword: '',
            videoType: [],
            radio: 'All',
            videoTypeChilds: [],
            videoList: [],
            tSelecteVal: [],
            languages: [],
            languageFilters: ['en']
        };
    },

    mounted() {
        initDicts(['MaterialLanguage']).then(res => {
            this.languages = res.MaterialLanguage;
        });
    },
    async created() {
        // this.search()
        await this.videoTypeAll()
        await this.search()
    },
    filters: {
        dataSplit(value) {
            if (value) {
                return value.split(' ')[0]
            }
            return value
        }
    },
    methods: {
        download({src}) {
            window.open(src)
        },
        async selectedEvent(value) {
            if (!this.tSelecteVal.includes(value.id)) {
                this.tSelecteVal.push(value.id)
            } else {
                this.tSelecteVal.splice(this.tSelecteVal.indexOf(value.id), 1)
            }
            await this.search()
        },
        async videoTypeAll() {
            const {data: {data, isSuccess}} = await getVideoTypeAll()
            if (isSuccess) {
                this.videoType = data
            }
        },
        async getVideoTypeChilds() {
            this.tSelecteVal = []
            // 点击父单选按钮后查询子项选择器
            const {data: {data, isSuccess}} = await getVideoTypeChilds(this.radio)
            if (isSuccess) {
                this.videoTypeChilds = data
                if (data.length) {
                    this.selectedEvent(this.videoTypeChilds[0])
                }
            }
            if (!data.length) {
                await this.search()
            }
        },
        async radioChangeEvent() {
            if (this.radio === 'All') {
                this.tSelecteVal = []
                this.videoTypeChilds = []
                await this.search()
            } else {
                await this. getVideoTypeChilds()
            }
        },
        async search() {
            const {data: {data, isSuccess}} = await listAll({
                type: this.radio,
                title: this.keyword,
                typeChild: this.tSelecteVal,
                language: this.languageFilters
            })
            if (isSuccess) {
                this.videoList = data
            }
        },
        jump2Other(url) {
            this.$refs.videoPopup.popup(url);
        },
    },
};
</script>

<style lang="less" scoped>
/* 用min-width的时候，小的放上面，大的放下面 */
.video-library {
    // position: absolute;
    width: 100%;
    min-height: inherit;
    background-color: #f7fafb;
}
.operation-bar {
    background-color: #ffffff;
}
.vedio-list {
    padding-bottom: 30px;
    .vedio {
        // float: left;
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        
        .vedio-content {
            width: 426.66px;
            // height: 362px;
            height: 100%;
            background-color: #ffffff;
            // border-radius: 8px;
            clip-path: inset(0 round 8px);
            .vedio-cover {
                width: 426.66px;
                height: 251px;
                position: relative;

                .partner-voices-item-btn {
                    cursor: pointer;
                    width: 86px;
                    height: 86px;
                    top: 127px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                }
                // /deep/ .video-player {
                //     height: 100%;
                //     width: 100%;
                // }
            }
            .vedio-title {
                padding-left: 18px;
                padding-top: 20px;
                font-size: 18px;
            }
            .pubdate {
                padding: 15px 18px;
                font-size: 16px;
                color: rgba(102, 102, 102, 1);
                display: flex;
                justify-content: space-between;
                align-items: center;
                .download {
                    cursor: pointer;
                    display: none;
                    &:hover {
                        color: #c7000b;
                    }
                }
            }
            
        }
    }
}
.search-bar {
    margin-top: 30px;
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0px;
        color: rgba(51, 51, 51, 1);
        text-align: left;
        vertical-align: top;
    }

    .tabs-header-search {
    //   display: flex;
    //   align-items: center;
    //   flex: 1;
    //   width: 100%;
    //   justify-content: flex-end;
    //   padding: 0 20px;

      /deep/ .el-input {
        width: 472px;
        border: none;

        input {
            border-radius: 25px;
            height: 50px;
        }

        .el-input__suffix {
            right: 12px;
            // #c7000b
            &:hover {
                color: #c7000b;
            }
            .el-input__icon {
                font-size: 24px !important;
                line-height: 50px;
            }
        }
      }
    }

}
.radios-bar {
    // padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    /deep/.el-radio {
        margin-right: 88px;
        margin-bottom: 14px;
    }
    /deep/ .el-radio__label {
        font-size: 16px;
        padding: 4px 4px;
    }
    /deep/.el-radio__input {
        display: none;
    }
    /deep/.el-radio__input.is-checked+.el-radio__label {
        border-bottom: 3px solid #c7000b;
        // border-radius: 5px;
    }
}

.doc-filter-list {
    font-size: 14px;
    display: flex;
    align-items: baseline;
    padding-top: 12px;
    margin-bottom: 30px;
}

.doc-filter-selectors {
    background-color: #f6f7f8;
    border: 1px solid #e5e5e5;
    padding: 10px;
    /deep/.el-checkbox {
        padding: 4px 0;
    }
}

.doc-filter-label {
    min-width: 70px;
    // font-weight: 600;
    font-size: 16px;
    padding-right: 44px;
}

.doc-filter-value {
    flex: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: -1px;
}

.doc-filter-item {
    // margin-bottom: 15px;
    margin-right: 20px;
}

.t-selector {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
        .t-options {
            display: flex;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            .t-option-shell {
                flex-shrink: 0;
                background: #ffffff;
                margin-right: 20px;
                border-radius: 10px;
                .t-option {
                    line-height: 32px;
                    padding: 0 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    span {
                        display: inline-block;
                        font-size: 14px;
                    }
                }
                .is-selectd {
                    background-color: #c7000a24;
                    span {
                        color: #c7000b;
                    }
                }
            }
        }
    
}
@media screen and (min-width: 0px) and (max-width: 991px) {
    .container {
        width: 100%;
        .search-bar {
            .tabs-header-search {
                /deep/ .el-input {
                    width: 200px;
                }
            }
        }
    }
    .doc-filter-value {
        margin-right: 0px;
    }
    .radios-bar {
        /deep/.el-radio {
            margin-right: 16px;
            margin-bottom: 14px;
        }
    }
    .vedio-list {
        .vedio {
            width: calc(100vw - 30px);
            .vedio-content {
                width: 100%;
                .vedio-cover {
                    width: 100%;
                }
            }
        }
    }
}

// @media (min-width: 768px) {
//   .container {
//     width: 750px;
//     .search-bar {
//         .tabs-header-search {
//             /deep/ .el-input {
//                 width: 270px;
//             }
//         }
//     }
    
//   }
// }

@media (min-width: 992px) {
  .container {
    width: 970px;
    .search-bar {
        .tabs-header-search {
            /deep/ .el-input {
                width: 270px;
            }
        }
    }
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1400px !important;
    .search-bar {
        .tabs-header-search {
            /deep/ .el-input {
                width: 472px;
            }
        }
    }
  }
}
</style>